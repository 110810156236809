import React from 'react';
import OnSale from './OnSale';
import FreeShipping from './FreeShipping';
import NewBadge from './NewBadge';

type Props = {
    option: 'free-shipping' | 'on-sale' | 'new';
    classNames?: string;
};

const Badge = (props: Props) => {
    if (props.option === 'on-sale') {
        return <OnSale classNames={props.classNames} />;
    }

    if (props.option === 'free-shipping') {
        return <FreeShipping classNames={props.classNames} />;
    }

    if (props.option === 'new') {
        return <NewBadge classNames={props.classNames} />;
    }
    return <></>;
};

export default Badge;
