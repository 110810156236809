import React, { useState } from 'react';
import { numberFormat } from '../../../utils/product/product';
import Link from 'next/link';
import { seoUrlParser } from '../../../utils/helper';
import { ITSProduct } from '../../../utils/typesense/types/product';
import { cleanSource } from '../../../utils/typesense/image';
import HTMLReactParser from 'html-react-parser';
import { isWithInMonthsAgo, toDateTime } from '../../../utils/DateHelper';
import { isEmpty } from 'lodash';
import { ITSImage } from '../../../utils/typesense/types/image';
import ProductCardImage from './ProductCardImage';
import Badge from '../../common/badge';
import dynamic from 'next/dynamic';

type Props = {
  product: ITSProduct;
  showRating: boolean;
  prefetch?: boolean;
};

const ProductRating = dynamic(() => import('../ProductRating'), {
  loading: () => <p>Loading...</p>,
});

const ProductCardBadge = ({ product }: { product: ITSProduct }) => {
  const createdDate = toDateTime(product.createdAt);
  const isThreeMonthsAgo = isWithInMonthsAgo(createdDate, 3);

  if (product.shippingClass && product.shippingClass.includes('free')) {
    return <Badge option="free-shipping" classNames="right-2 top-3" />;
  }

  if (product.onSale) {
    return <Badge option="on-sale" />;
  }

  if (isThreeMonthsAgo) {
    return <Badge option="new" />;
  }

  return <></>;
};

export default function ProductCard(props: Props) {
  const { product, showRating = false, prefetch = true } = props;
  const productLink = seoUrlParser(product.permalink);
  const thumbnailSrc = cleanSource(product.thumbnail?.src as string);
  const galleryImages = product.galleryImages;

  const mainImage: ITSImage = {
    src: thumbnailSrc,
    altText: product.thumbnail?.altText
      ? (product.thumbnail.altText as string)
      : (product.name as string),
  };

  let secondaryImage: ITSImage | undefined;
  if (typeof galleryImages !== 'undefined' && !isEmpty(galleryImages[0])) {
    secondaryImage = galleryImages[0];
  }

  const onBackOrder = product.stockStatus === 'onbackorder';

  return (
    <div key={product.id} className="group relative">
      <div className="relative w-full overflow-hidden bg-gray-200 groupsss-hover:opacity-75  xl:h-[312px]">
        <ProductCardImage
          mainImage={mainImage}
          secondaryImage={secondaryImage}
        />
      </div>

      <ProductCardBadge product={product} />

      {product.stockStatus !== 'instock' && !onBackOrder && (
        <div className="absolute inset-x-0 -top-40 md:-top-28 lg:-top-24 xl:-top-4 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
          <p className="w-full text-center py-2 relative uppercase text-brand-primary text-xs md:text-sm font-bold bg-[rgba(255,255,255,.88)] -mb-3">
            Not currently available
          </p>
        </div>
      )}
      <div className=" flex flex-col items-center lg:items-start justify-between lg:flex-row mt-2.5 ">
        <h3 className="leading-[5px] text-center lg:text-left uppercase mb-2 lg:mb-0">
          <Link
            href={productLink}
            className="text-xs lg:text-sm text-black font-normal tracking-[0.2em]"
            prefetch={prefetch}
          >
            <span aria-hidden="true" className="absolute inset-0" />
            {HTMLReactParser(product.name as string)}
          </Link>
        </h3>
        <div className="text-center w-full lg:w-auto">
          {product.onSale ? (
            <>
              <p className="text-xs lg:text-sm font-semibold tracking-[0.2em] text-brand-gray line-through">
                {numberFormat(product.regularPrice?.AUD)}
              </p>
              <p className="text-xs lg:text-sm font-semibold tracking-[0.2em]">
                {numberFormat(product.price?.AUD)}
              </p>
            </>
          ) : (
            <>
              <p className="text-xs lg:text-sm font-semibold tracking-[0.2em]">
                {numberFormat(product.price?.AUD)}
              </p>
            </>
          )}
        </div>
      </div>
      {showRating ? <ProductRating sku={product.sku as string} /> : null}
    </div>
  );
}
