export const toDateTime = (seconds: number) => {
  var t = new Date(0); // Epoch
  t.setSeconds(seconds);
  return t;
};

export const toUnixTimeStamp = (date: Date) => {
  var unixTimeStamp = Math.floor(date.getTime() / 1000);
  return unixTimeStamp;
};

export const getMonthsAgo = (month: number) => {
  const dateMonthsAgo = new Date(
    new Date().setMonth(new Date().getMonth() - month),
  );

  return dateMonthsAgo;
};

export const isWithInMonthsAgo = (date: Date, month: number) => {
  const monthsAgo = getMonthsAgo(month);
  const unixMonthsAgo = toUnixTimeStamp(monthsAgo);

  const unixDateTime = toUnixTimeStamp(date);

  if (unixDateTime >= unixMonthsAgo) {
    return true;
  }

  return false;
};

const DateHelper = {
  toDateTime,
  toUnixTimeStamp,
  getMonthsAgo,
  isWithInMonthsAgo,
};

export default DateHelper;
