import React from 'react';

type Props = {
    classNames?: string;
};

const FreeShipping = (props: Props) => {
    return (
        <div className="absolute rounded-full bg-brand-primary-light uppercase w-[70px] h-[70px] flex items-center flex-col  justify-center text-xs text-white right-2 top-3">
            <strong className="font-bold text-xs font-sans">Free</strong>
            <p className="font-normal text-[10px] font-sans text-center">
                Standard Shipping
            </p>
        </div>
    );
};

export default FreeShipping;
