import React from 'react';
import { cn } from '../../../utils';

type Props = {
  classNames?: string;
};

const OnSale = (props: Props) => {
  return (
    <span
      className={cn(
        'absolute rounded-full bg-brand-red uppercase w-[58px] h-[58px] flex items-center justify-center text-xs text-white font-bold right-2 top-3',
        props.classNames ? props.classNames : '',
      )}
    >
      Sale
    </span>
  );
};

export default OnSale;
