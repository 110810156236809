import React from 'react';
import { ITSImage } from '../../../utils/typesense/types/image';
import Image from '../../common/Image';
import getImgUrl from '../../../utils/getImageUrl';
import { cn } from '../../../utils';

type ProductCardImageProps = {
  mainImage: ITSImage;
  secondaryImage?: ITSImage;
};

const ProductCardImage = ({
  mainImage,
  secondaryImage,
}: ProductCardImageProps) => {
  return (
    <>
      {typeof secondaryImage !== 'undefined' && (
        <Image
          src={getImgUrl(secondaryImage.src)}
          alt={secondaryImage.altText}
          width={359}
          height={312}
          className={cn(
            'w-full h-full object-center object-cover lg:w-full lg:h-full group-hover:transition group-hover:ease-linear group-hover:duration-300 ease-linear duration-300',
            'absolute opacity-0 group-hover:opacity-100',
          )}
        />
      )}
      <Image
        src={getImgUrl(mainImage.src)}
        alt={mainImage.altText}
        width={359}
        height={312}
        className={cn(
          'w-full h-full object-center object-cover lg:w-full lg:h-full group-hover:transition group-hover:ease-linear group-hover:duration-300 ease-linear duration-300',
          'static opacity-100 group-hover:opacity-100',
        )}
      />
    </>
  );
};

export default ProductCardImage;
